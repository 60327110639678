<template>
  <div class="tools container">
    <div class="topHead" v-if="pageType == 'brand'">
        <navigation />
    </div>
    <div class="contentBox col-md-8 mt-25">
    <div class="contentBox__head">Tools</div>
    <div class="contentBox__body">
      <div class="card-body">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" class="user-form">           
              <b-card
                no-body
                class="users-list" style="overflow: visible;">
                <b-card-header header-tag="header" role="tab">
                <h5 class="card-title">Login As</h5>
                  <div class="button-row">
                    <base-multi-select
                      :list="getAllAccounts"
                      :multiple="false"
                      label="toolsDisplayName"
                      id="id"
                      class="users-list"
                      @input="getSelectedUser"
                      v-model="selectedUser"
                    >
                    </base-multi-select>
                    <button
                      class="btn btn-primary"
                    >
                      {{ buttonText }}
                    </button>
                  </div>
                </b-card-header>
              </b-card>
            </b-form>
          </validation-observer>
        </div>
    </div>
    </div>
    <hr>
    <UserStatsChart/>
    <hr>
    <div class="campaign-details">
      <button 
        class="btn btn-primary campaign-button" 
        @click="displayCampaignCard"
        :disabled="disabled == 1"
      >
        Show Campaigns
      </button>
      <div v-if="$apollo.loading && this.isFirst">
        <base-skeleton-loader
          type="discover"
          :count="this.limit"
          v-if="$apollo.queries.getAllCampaigns.loading && isFirst"
        ></base-skeleton-loader>
      </div>
      <div class="row" v-else>
      <div v-for="campaign in getAllCampaigns" v-if="displayCampaigns">
        <!--start-->
        <div class="campaignCard card">
          <ul class="campaignCard__user">
            <span class="campaignCard__user--status"></span>
              <li class="campaignCard__user--icon">
                <img
                  :src="`${$config.IMG_HOST}/50x50/${campaign.logo}`"
                  alt=""
                  class="img-fluid"
                />
              </li>
              <li class="campaignCard__name">
                {{ campaign.name }}
                <div v-if="campaign.subscription">
                <span v-if="campaign.subscription.state">{{
                  campaign.subscription.state
                }}</span>
                <span class="onMobile" v-if="campaign.subscription.timestamp">{{
                  numberToDate(campaign.subscription.timestamp)
                }}</span>
                </div>
              </li>

              <li class="campaignCard__budget">
                Budget
                <span>{{ formatPrice(campaign.budget) }}</span>
              </li>
              <li class="campaignCard__filters">
                Filters
                <span>Price: {{formatPrice(campaign.priceStart)}} - {{formatPrice(campaign.priceEnd)}}</span>
                <span>Age: {{campaign.ageStart}} - {{campaign.ageEnd}}</span>
                <span>Followers: {{campaign.followers}}</span>
                <span>Location: {{campaign.location.city}}</span>
                <span>Interests: {{campaign.interests}}</span>
              </li>
              <li class="campaignCard__influencers">
                Influencers
                <span>{{campaign.influencers}}</span>
              </li>

          </ul>
          <ul class="campaignCard__date" v-if="campaign.subscription">
              <li v-if="campaign.subscription.timestamp !== null">
                {{ numberToDate(campaign.subscription.timestamp) }}
              </li>
              <li>
                <a href="#" v-if="campaign.price">{{ campaign.price }}</a>
              </li>
          </ul>
          
        </div>
      </div>
      <infinite-loading
        @infinite="infiniteHandler"
        :identifier="infiniteId"
        :distance="30"
        v-if="!isFirst && displayCampaigns"
      >
      </infinite-loading>
    </div>
    <!--<div
      class="no-record"
      v-if="getAllCampaigns.length == 0 && !$apollo.loading.influencers"
    >
      No Record Found
    </div>-->
      <!-- End --> 

    </div>
  </div>
</template>

<script>

import { onLogin, apolloClient } from "@/vue-apollo";
import { mapActions,mapGetters } from "vuex";
import { GET_ALL_ACCOUNTS, CHECK_USERNAME } from "@/graphql/common/query";
import { ADMIN_LOGIN_USER } from "@/graphql/common/mutations";
import { ALL_CAMPAIGNS } from "@/graphql/brand/query";

export default {
  components: {
    navigation: () =>
      import(
        /* webpackChunkName: "navigations" */ "@/components/brand/Navigation.vue"
      ),
      UserStatsChart: () =>
      import(
        /* webpackChunkName: "statics" */ "@/components/common/UserStatsChart.vue"
      ),
  },
  data(){
    return {
      getAllCampaigns: [],
      buttonText: "Login",
      getAllAccounts: [],
      selectedUser: "",
      loginDetails: {
        email: "",
      },
      userType: 0,
      pageType: null,
      page: 1,
      limit: 20,
      state: "active",
      displayCampaigns: false,
      disabled: 1,
      isFirst: true,
      infiniteId: +new Date(),
      hasMore: true,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  apollo: {
    getAllAccounts: {
      query: GET_ALL_ACCOUNTS,
    },
    getAllCampaigns: {
      query: ALL_CAMPAIGNS,
      variables() {
        return {
          page:  1,
          limit: this.limit,
          state: this.state,
        };
      },
      result(data) {
        if (data.data.getAllCampaigns.length !== 0) {
          this.disabled = 0;
        }
      },
    },
  },
  watch: {
    getAllCampaigns() {
      this.isFirst = false;
    },
  },
  mounted() {
    this.pageType = this.$route.path == "/brand/tools" ? "brand" : "influencer";
    if (!this.user.displayTools) {
      /*this.user.type == 0
              ? this.$router.push("/user")
              : this.$router.push("/brand");*/
      this.user.type == 0
              ? this.$router.push(this.$i18nRoute({name: "userDashboard"}))
              : this.$router.push(this.$i18nRoute({name: "brandDashboard"}));
    }
  },
  methods: {
    ...mapActions(["logOut"]),
    getSelectedUser() {
      this.loginDetails.email = this.selectedUser ? this.selectedUser.email : '';
      this.userType = this.selectedUser ? this.selectedUser.type : null;
    },
    async onSubmit() {
      this.logOut();
      this.buttonText = "Login as ...";
      await this.$apollo
      .mutate({
        mutation: ADMIN_LOGIN_USER,
        variables: this.loginDetails,
      })
      .then((data) => {
        if (data) {
          const token = JSON.stringify(
            data.data.createLoginSession.token
          );
          this.$store.commit("SET_TOKEN", token);
          onLogin(apolloClient, token).then(() => {
            this.$store.dispatch("setUser").then(() => {
              this.userType == 0
              ? this.$router.push("/user")
              : this.$router.push("/brand");
            });
          });
        }
      })
      .catch((e) => {
        this.handleError(e);
      });
    },
    async displayCampaignCard(){
      if(this.displayCampaigns == false)
        this.displayCampaigns = true;
      else
        this.displayCampaigns = false;
    },
    infiniteHandler($state) {
      if (this.hasMore) {
        setTimeout(() => {
          try {
            this.page++;
            this.$apollo.queries.getAllCampaigns.fetchMore({
              variables: {
                page: this.page,
                limit: this.limit,
                state: this.state,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newGetAllCampaigns = fetchMoreResult.getAllCampaigns;
                if (newGetAllCampaigns.length) {
                  $state.loaded();
                } else {
                  $state.complete();
                  this.hasMore = false;
                }
                if (newGetAllCampaigns.length < this.limit) {
                  this.hasMore = false;
                }
                return {
                  getAllCampaigns: [
                    ...previousResult.getAllCampaigns,
                    ...newGetAllCampaigns,
                  ],
                };
              },
            });
          } catch (err) {
            $state.error();
          }
        }, 500);
      } else {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .users-list {
    .multiselect {
      &__content-wrapper {
        max-height: 250px !important;
        overflow: auto !important;
      }
    }
    .contentBox {
      max-width: 75%!important;
    }  
  }
  .users-list-card {
    overflow: visible;
    background: #fff;
  }
  .card-tools {
    background: #fff;
    border-radius: 20px 20px 0 0;
  }
  .users-list{
    .card-header{
      border: 0px;
      border-radius: 10px;
    }
  }
  .user-form {
    max-width: 100%;
  }
  
}
.usersStatsDate {
  width:15%
}
.filters__item {
  margin-bottom: 10px;
} 
.userStats {
  margin-bottom: rem(16px);
  ul {
    background: #fff;
    padding: rem(16px) rem(70px) rem(26px) rem(85px);
    list-style: none;
    /*width: 620px;*/
    width: 620px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      padding: rem(16px) rem(50px) rem(26px);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: rem(25px) rem(20px);
      flex-wrap: wrap;
    }
    li {
      font-size: rem(18px);
      color: var(--textPrimary);
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        width: 48%;
        text-align: center;
      }
      span {
        color: var(--primary);
        display: block;
        margin-top: rem(17px);
        font-size: rem(40px);
        ::v-deep {
          i {
            font-style: normal;
            font-size: rem(26px);
          }
        }
      }
    }
  }
}

.campaignCard {
  position: relative;
  padding: rem(14px);
  padding-right: rem(20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 6px !important;
  margin-bottom: rem(14px);
  &__user {
    display: flex;
    align-items: center;
    list-style: none;
    &--status {
      position: absolute;
      width: 14px;
      height: 14px;
      background: #24d694;
      border-radius: 50%;
      top: 50px;
      z-index: 1;
      left: 50px;
    }
    &--icon {
      position: relative;
      width: 50px;
      min-width: 50px;
      height: 40px;
      background: #d8b4fa;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: rem(15px);
      overflow: hidden;
      // img {
      //  height: 100%;
      //  width: 100%;
      // }
    }
    li {
      font-size: rem(18px);
      font-weight: 500;
      color: var(--textPrimary);
      span {
        display: block;
        color: var(--textSecondary);
        font-size: rem(14px);
        font-weight: 500;
      }
    }
  }
  &__date {
    list-style: none;
    display: flex;
    align-items: center;
    li {
      font-size: rem(14px);
      font-weight: 600;
      color: var(--primary);
      padding: 0 rem(16px);
      a {
        position: relative;
        font-weight: 700;
        color: var(--primary);
        z-index: 10;
        &::before {
          position: absolute;
          content: "";
          background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.538' height='23.913' viewBox='0 0 13.538 23.913'%3E%3Cpath id='Path_217' data-name='Path 217' d='M4871.167,592.811l9.916,9.683-9.916,9.987' transform='translate(-4869.045 -590.69)' fill='none' stroke='%239637f1' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          width: 14px;
          height: 24px;
          top: -1px;
          right: -20px;
          transition: 0.3s ease-in-out;
          @media screen and (max-width: 991px) {
            top: -3px;
          }
        }
        &:hover {
          @media screen and (min-width: 1025px) {
            opacity: 0.8;
            &::before {
              right: -23px;
            }
          }
        }
      }
      &:first-child {
        position: relative;
        @media screen and (max-width: 991px) {
          display: none;
        }
        &::before {
          position: absolute;
          content: "";
          height: 60px;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: #c9ced6;
          border: 1px solid #c9ced6;
          border-radius: 50px;
          overflow: hidden;
        }
      }
    }
  }
  .onMobile {
    display: none;
    font-size: rem(14px);
    color: var(--primary);
    @media screen and (max-width: 991px) {
      display: block !important;
    }
  }
}

.total-accounts.users-list {
  width: 50%;
  display: inline-block;
  overflow-y: scroll;
  max-height: 400px;
}
.hr-line {
  width: 50%;
}
.campaign-details{
  margin-top:15px;
}
.campaign-button{
 margin-bottom:15px; 
}
.campaignCard{
  flex-wrap:wrap;
  flex-direction:initial;

  &__user{
    flex-wrap:wrap;
    flex:100%;
    max-width:100%;
  }
  &__name{
    flex:300px;
    max-width:300px;
  }
  &__budget {
  margin-left: 20px;
  margin-right: 10px;
  flex:150px;
  max-width:150px;
  }
  &__filters{
    margin-left: 10px;
    margin-right: 10px;
     flex:250px;
  max-width:250px;
  }  
  &__influencers {
    margin-left: 10px;
    margin-right: 10px;
    flex:150px;
    max-width:150px;

  }
}
</style>
